import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { Observable } from 'rxjs';
import { AutoridadePesquisa } from '../../../model/controles/satisfacao-cliente/AutoridadePesquisa';

@Injectable({
  providedIn: 'root'
})
export class AutoridadePesquisaService {
  private url = `${environment.url}/controles/autorizar-participacao-pesquisa`

  constructor(
    private httpClient: HttpClient
  ) { 

  }

  public cadastrarAutoridadePesquisa(satisfacaoCliente:any): Observable<AutoridadePesquisa> {
    return this.httpClient.post<AutoridadePesquisa>(`${this.url}`,satisfacaoCliente);
  }
  public atualizarAutoridadePesquisa(satisfacaoCliente:any): Observable<AutoridadePesquisa> {
    return this.httpClient.put<AutoridadePesquisa>(`${this.url}`,satisfacaoCliente);
  }
  public consultaAutorizacoesPorIdPesquisa(idPesquisa:number): Observable<AutoridadePesquisa[]> {
    return this.httpClient.get<AutoridadePesquisa[]>(`${this.url}/autorizacoes/${idPesquisa}`);
  }
  public consultaAutorizacoesPorToken(token:string): Observable<AutoridadePesquisa> {
    return this.httpClient.get<AutoridadePesquisa>(`${this.url}/${token}`);
  }
  public enviarEmailsParticipacao(idPesquisa:number): Observable<any> {
    return this.httpClient.get<any>(`${this.url}/enviar-email/${idPesquisa}`);
  }
  public excluirAutorizacao(idPesquisa:number): Observable<any> {
    return this.httpClient.delete<any>(`${this.url}/${idPesquisa}`);
  }

}
